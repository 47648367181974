import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";

type ViewportSizeContextStore = [
  {
    viewPortIsLessThan768: boolean;
    viewPortIsMoreThan768: boolean;
    viewPortIsLessThan1024: boolean;
    viewPortIsLessThan1210: boolean;
    viewPortIsLessThan1280: boolean;
  },
  {
    setViewPortIsLessThan768?: (is: boolean) => void;
    setViewPortIsMoreThan768?: (is: boolean) => void;
    setViewPortIsLessThan1024?: (is: boolean) => void;
    setViewPortIsLessThan1210?: (is: boolean) => void;
    setViewPortIsLessThan1280?: (is: boolean) => void;
  },
];

const ViewportSizeContext = createContext<ViewportSizeContextStore>([
  {
    viewPortIsLessThan768: false,
    viewPortIsMoreThan768: false,
    viewPortIsLessThan1024: false,
    viewPortIsLessThan1210: false,
    viewPortIsLessThan1280: false,
  },
  {},
]);

export const ViewportSizeContextProvider: ParentComponent<{
  viewPortIsLessThan768: boolean;
  viewPortIsMoreThan768: boolean;
  viewPortIsLessThan1024: boolean;
  viewPortIsLessThan1210: boolean;
  viewPortIsLessThan1280: boolean;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      viewPortIsLessThan768: props.viewPortIsLessThan768 || false,
      // eslint-disable-next-line solid/reactivity
      viewPortIsMoreThan768: props.viewPortIsMoreThan768 || false,
      // eslint-disable-next-line solid/reactivity
      viewPortIsLessThan1024: props.viewPortIsLessThan1024 || false,
      // eslint-disable-next-line solid/reactivity
      viewPortIsLessThan1210: props.viewPortIsLessThan1210 || false,
      // eslint-disable-next-line solid/reactivity
      viewPortIsLessThan1280: props.viewPortIsLessThan1280 || false,
    }),
    store: ViewportSizeContextStore = [
      state,
      {
        setViewPortIsLessThan768(is: boolean) {
          setState("viewPortIsLessThan768", is);
        },
        setViewPortIsMoreThan768(is: boolean) {
          setState("viewPortIsMoreThan768", is);
        },
        setViewPortIsLessThan1024(is: boolean) {
          setState("viewPortIsLessThan1024", is);
        },
        setViewPortIsLessThan1210(is: boolean) {
          setState("viewPortIsLessThan1210", is);
        },
        setViewPortIsLessThan1280(is: boolean) {
          setState("viewPortIsLessThan1280", is);
        },
      },
    ];

  return (
    <ViewportSizeContext.Provider value={store}>
      {props.children}
    </ViewportSizeContext.Provider>
  );
};

export function useViewportSizeContext() {
  const context = useContext(ViewportSizeContext);
  if (context === undefined) {
    throw new Error(
      "useViewportSizeContext must be used within a ViewportSizeContext.Provider",
    );
  }
  return context;
}
